<template>
  <div>
    <top-agent-self-agent-monthly-bill v-if="this.$store.getters.userRole === 'top_agent'" />
    <agency-self-agent-monthly-bill v-else-if="this.$store.getters.userRole === 'agency'" />
    <merchant-self-agent-monthly-bill v-else-if="this.$store.getters.userRole === 'merchant'" />
  </div>
</template>

<script>

export default {
  name: 'ShowAgentMonthlyBill',
  components: {
    TopAgentSelfAgentMonthlyBill: () => import('@/views/agent_monthly_bills/top_agent_self/Show'),
    AgencySelfAgentMonthlyBill: () => import('@/views/agent_monthly_bills/agency_self/Show'),
    MerchantSelfAgentMonthlyBill: () => import('@/views/agent_monthly_bills/agency_self/Show')
  }
}
</script>
